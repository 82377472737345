import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Img1 from '../../assets/images/listing/Img1.webp';
import Img2 from '../../assets/images/listing/Img2.webp';
import Img3 from '../../assets/images/listing/Img3.webp';
import SingleListing from './SingleListing';

const Penthouses = () => {

  return (
    <>
    <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto text-center py-5" data-aos="fade-up">
     No Property Found !
    </div>
    </>
  );
}

export default Penthouses;
