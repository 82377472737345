import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer'
import BannerImg from '../assets/images/banners/Banner1.webp';
// import BlogsImg from '../assets/images/blogs/blog.jpg';
import { EnvelopeFill, Facebook, GeoAltFill, Instagram, Linkedin, PhoneFill, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons'

const Blog = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [blog, setBlog] = React.useState([])
    const [contentLoading, setContentLoading] = React.useState(true)

    useEffect(() => {
        // get last part of url
        const slug = window.location.pathname.split("/").filter((item) => item !== "").slice(-1)[0];
        
        // get blog by slug
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/slug/${slug}`).then(res => {
            if(res.data){
                setBlog(res.data)
            }else{
                setBlog([])
            }
        }).catch(err => {
            console.log(err)
        }
        ).finally (() => {
            setContentLoading(false)
        })

    }, [])

    return (
                <>
        <Helmet>
            <meta charSet="utf-8" />
            <title> </title>
            <meta name="description" content="" />
            <link rel="canonical" href="https://800homes.ae/"/>
        </Helmet>
            <Header/>
            <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
                <div className="row justify-content-center align-items-center">
                    <div className="col-11 col-lg-10">
                        <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
                            Blog
                        </h1>
            </div>
            </div>
            </section>
            <div className='bg-page py-5 py-md-0' id="website-responsive">
                
                {/* <BannerCategory /> */}
                <section className='pb-md-4'>
                    <div className='container-fluid'>
                        <div className='row pt-md-5 my-md-5 gy-4' id="">
                        {contentLoading ?
                            <div className='col-md-12 col-sm-12 text-center'>
                                <Spinner animation="border" variant="white" />
                            </div>
                            :
                            <>
                            <div className="col-md-8 mx-auto">
                                <div className="">
                                    <div className="text-center shadow mb-4">
                                    <img className='w-100' src={blog?.featured_image1} alt={blog?.title} />
                                </div>
                                    <div className='mt-5'>
                                    <div className="pb-md-5" data-aos="fade-up">
                                        <div className="pb-4 text-white fs-ss-30 lh-sm fw-500">
                                            {blog?.title}
                                        </div>
                                        <div className="text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
                                            <div dangerouslySetInnerHTML={{__html: `${blog?.content1}`}}></div>    
                                        </div>
                                        {blog?.featured_image2 &&
                                            <div className="pb-4 text-white fs-ss-30 lh-sm fw-500">
                                                <img className='w-100' src={blog?.featured_image2} alt={blog?.title} />
                                            </div>
                                        }
                                        {blog?.content2 &&
                                            <div className="text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
                                                <div dangerouslySetInnerHTML={{__html: `${blog?.content2}`}}></div>
                                            </div>
                                        }   
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 mx-auto">
                            
                            <div className="border-white border-1 border mt-4 p-4">
                            <div className='fs-ss-30 fw-500 lh-sm text-white'>
        Get In Touch
        </div>
        <div className="fs-ss-16 text-grey fw-300 py-3 lh-1-7em">
        Contact us via phone, email, or in person, and let's discuss how we can help you find the perfect home.
        </div>

        <div className="pt-2 pt-md-3">
              <div className="pb-3"> 
               <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="tel:80046637" target='_blank'> <TelephoneFill className='text-ss-primary me-2'/> 80046637 </a> 
               </div>
               <div className="pb-3"> 
               <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="tel:+971585746637" target='_blank'> <PhoneFill className='text-ss-primary me-2'/> +971-58-574-6637 </a> 
               </div>
               <div className="pb-3"> 
               <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" target='_blank'> <Whatsapp className='text-ss-primary me-2'/> +971-58-574-6637 </a> 
               </div>
               <div className="pb-3"> 
               <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="mailto:inquiry@800homes.ae" target='_blank'> <EnvelopeFill className='text-ss-primary me-2'/> inquiry@800homes.ae </a> 
               </div>
               <div className=""> 
               <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="https://maps.app.goo.gl/sRSzXd77JFuYDpC27" target='_blank'> <GeoAltFill className='text-ss-primary me-2'/> 
               Ibn Batuta Gate Office - Dubai, UAE
               </a> 
               </div>
                </div>
                            </div>
                            <div className="border-white border-1 border p-4 mt-4">
                                <div className="fs-ss-18 text-white py-3">
                                Follow Us
                                </div>
                                <div className="text-ss-secondary fs-ss-22 text-ss-primary">
                                    <a href="mailto:inquiry@800homes.ae" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <EnvelopeFill className='me-3 mb-4'/> </a>
                                    <a href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <Whatsapp className='me-3 mb-4'/> </a>
                                    <a href="https://www.facebook.com/800Homes" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <Facebook className='me-3 mb-4'/> </a>
                                    <a href="https://www.instagram.com/800homesae/" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <Instagram className='me-3 mb-4'/> </a>
                                    <a href="https://www.tiktok.com/@800homes" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <Tiktok className='me-3 mb-4'/> </a>
                                    <a href="https://www.linkedin.com/company/800-homes-real-estate-brokerage-firm/" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <Linkedin className='me-3 mb-4'/> </a>
                                    </div>  
                                    </div>
                            </div>
                            </>
                        }
                        </div>
                    </div>
                </section>

            </div>
            
        <Footer/>

        </>
    )
}

export default Blog