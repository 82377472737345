import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import MainPageOfListing from '../components/listing/MainPageOfListing';
import BannerImg from '../assets/images/banners/Banner1.webp';

const BuyProperty = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Buy Property in Dubai with 800 Homes | Trusted Real Estate Brokers </title>
        <meta name="description" content="Explore premium properties to buy in Dubai with 800 Homes. As leading brokers in Dubai, we help you find the perfect home or investment property in the city’s best locations." />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Buy Property in Dubai with 800 Homes | Trusted Real Estate Brokers" />
        <meta property="og:description" content="Explore premium properties to buy in Dubai with 800 Homes. As leading brokers in Dubai, we help you find the perfect home or investment property in the city’s best locations." />
        <meta property="og:url" content="https://800homes.ae/properties-for-sale" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.ae/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.ae/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Explore premium properties to buy in Dubai with 800 Homes. As leading brokers in Dubai, we help you find the perfect home or investment property in the city’s best locations." />
        <meta name="twitter:title" content="Buy Property in Dubai with 800 Homes | Trusted Real Estate Brokers" />
        <meta name="twitter:image" content="https://800homes.ae/og.webp"/>
        <link rel="canonical" href="https://800homes.ae/properties-for-sale"/>
        </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
            Buy Property
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid">
      <div className="row justify-content-center">
        <MainPageOfListing/>
      </div>
    </section>
    <div className="pt-0 pt-md-4" />
    <Footer/>
    </>
  );
}

export default BuyProperty;
