import React from "react";
import { EnvelopeFill, GeoAlt, GeoAltFill, TelephoneFill, Whatsapp } from "react-bootstrap-icons";
import {Link, useNavigate} from 'react-router-dom';
import Beds from '../../assets/images/listing/doublebed.png';
import Shower from '../../assets/images/listing/shower.png';
import SqFt from '../../assets/images/listing/ruller.png';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const SingleListing = (props) => {
  const images = [
    {
      original: props.Img1,
    },
    {
      original: props.Img2,
    },
    {
      original: props.Img3,
    },
  ];

  const navigate = useNavigate()
  
  const gotToNewPage = () => {
    navigate(props.Link)
  }

  return (
    <>
    <div id="listing" className="width-desktop-mobile text-grey border-1 border gy-5 border-white px-0 mx-md-3 mx-auto" data-aos="fade-up">

      {/* Image */}
      <div className="">
       <ImageGallery items={images} onClick={() => gotToNewPage()} />
      </div>

      {/* Title,Address, Category and Details */}
      <div className="ps-3 pt-3 pb-1 me-md-3">
        <Link to={props.Link} className="title-color text-grey align-text-line text-decoration-none fs-ss-22 fw-400">
          {props.Title}
        </Link>
        <div className="fs-ss-16 fw-400 pt-4 pb-1">
          <GeoAlt className="mb-1"/> {props.Address}
        </div>
        <div className="fs-ss-16 fw-300">
          {props.Category}
        </div>

        <div className="d-flex align-items-center fs-ss-14 fw-300 mt-2">
          <div className="me-3">
          <img src={Beds} className="listing-icons mb-2" alt="" />
          <span className=""> Beds: </span>           
          <span className=""> {props.Beds} </span> 
          </div>
          
          <div className="me-3">
          <img src={Shower} className="listing-icons mb-2" alt="" />
          <span className=""> Baths: </span>           
          <span className=""> {props.Baths} </span>                    
          </div>       
        </div>

        <div className="fs-ss-14 fw-300">
          <img src={SqFt} className="listing-icons me-1 mb-2" alt="" />
          <span className=""> {props.SqFt} </span> 
          </div>   

      </div>

      {/* Price */}
      <div className="text-uppercase fw-300 fs-ss-22 border-top border-1 border-white py-1 mx-3 mt-3">
        AED <span className=""> {props.Price} </span>
      </div>

      {/* Contact Us */}      
      <div className="row justify-content-center fs-ss-18 text-center border-white border-1 border-top gx-0 align-items-center">
          <a href="tel:+971585746637" className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > <TelephoneFill/> </a>
          <a href="mailto:inquiry@800homes.ae" className="col-4 border-start border-end border-1 border-white py-1 text-decoration-none text-ss-primary text-hover-ss-primary" > <EnvelopeFill/> </a>
          <a target="_blank" href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > <Whatsapp/> </a>
      </div>
    </div>
    </>
  )
}

export default SingleListing