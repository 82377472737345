import React from 'react';
import Listing1_Img1 from '../../assets/images/listing/listing1/1.webp';
import Listing1_Img2 from '../../assets/images/listing/listing1/2.webp';
import Listing1_Img3 from '../../assets/images/listing/listing1/3.webp';
import Listing1_Img4 from '../../assets/images/listing/listing1/4.webp';
import Listing1_Img5 from '../../assets/images/listing/listing1/5.webp';
import Listing1_Img6 from '../../assets/images/listing/listing1/6.webp';
import Listing1_Img7 from '../../assets/images/listing/listing1/7.webp';
import Listing1_Img8 from '../../assets/images/listing/listing1/8.webp';
import Listing1_Img9 from '../../assets/images/listing/listing1/9.webp';
import Listing1_Img10 from '../../assets/images/listing/listing1/10.webp';
import Listing1_Img11 from '../../assets/images/listing/listing1/11.webp';
import Listing1_Img12 from '../../assets/images/listing/listing1/12.webp';
import Listing1_Img13 from '../../assets/images/listing/listing1/13.webp';
import Listing1_Img14 from '../../assets/images/listing/listing1/14.webp';
import Listing1_Img15 from '../../assets/images/listing/listing1/15.webp';
import Listing1_Img16 from '../../assets/images/listing/listing1/16.webp';
import Listing1_Img17 from '../../assets/images/listing/listing1/17.webp';
import Listing1_Img18 from '../../assets/images/listing/listing1/18.webp';
import Listing1_Img19 from '../../assets/images/listing/listing1/19.webp';

import Listing2_Img1 from '../../assets/images/listing/listing5/1.webp';
import Listing2_Img2 from '../../assets/images/listing/listing5/2.webp';
import Listing2_Img3 from '../../assets/images/listing/listing5/3.webp';
import Listing2_Img4 from '../../assets/images/listing/listing5/4.webp';
import Listing2_Img5 from '../../assets/images/listing/listing5/5.webp';
import Listing2_Img6 from '../../assets/images/listing/listing5/6.webp';
import Listing2_Img7 from '../../assets/images/listing/listing5/7.webp';
import Listing2_Img8 from '../../assets/images/listing/listing5/8.webp';
import Listing2_Img9 from '../../assets/images/listing/listing5/9.webp';
import Listing2_Img10 from '../../assets/images/listing/listing5/10.webp';

import Listing3_Img1 from '../../assets/images/listing/listing2/1.webp';
import Listing3_Img2 from '../../assets/images/listing/listing2/2.webp';
import Listing3_Img3 from '../../assets/images/listing/listing2/3.webp';
import Listing3_Img4 from '../../assets/images/listing/listing2/4.webp';
import Listing3_Img5 from '../../assets/images/listing/listing2/5.webp';
import Listing3_Img6 from '../../assets/images/listing/listing2/6.webp';
import Listing3_Img7 from '../../assets/images/listing/listing2/7.webp';
import Listing3_Img8 from '../../assets/images/listing/listing2/8.webp';
import Listing3_Img9 from '../../assets/images/listing/listing2/9.webp';
import Listing3_Img10 from '../../assets/images/listing/listing2/10.webp';
import Listing3_Img11 from '../../assets/images/listing/listing2/11.webp';
import Listing3_Img12 from '../../assets/images/listing/listing2/12.webp';
import Listing3_Img13 from '../../assets/images/listing/listing2/13.webp';

import Listing4_Img1 from '../../assets/images/listing/listing3/1.webp';
import Listing4_Img2 from '../../assets/images/listing/listing3/2.webp';
import Listing4_Img3 from '../../assets/images/listing/listing3/3.webp';
import Listing4_Img4 from '../../assets/images/listing/listing3/4.webp';
import Listing4_Img5 from '../../assets/images/listing/listing3/5.webp';
import Listing4_Img6 from '../../assets/images/listing/listing3/6.webp';
import Listing4_Img7 from '../../assets/images/listing/listing3/7.webp';
import Listing4_Img8 from '../../assets/images/listing/listing3/8.webp';
import Listing4_Img9 from '../../assets/images/listing/listing3/9.webp';
import Listing4_Img10 from '../../assets/images/listing/listing3/10.webp';
import Listing4_Img11 from '../../assets/images/listing/listing3/11.webp';
import Listing4_Img12 from '../../assets/images/listing/listing3/12.webp';
import Listing4_Img13 from '../../assets/images/listing/listing3/13.webp';
import Listing4_Img14 from '../../assets/images/listing/listing3/14.webp';
import Listing4_Img15 from '../../assets/images/listing/listing3/15.webp';
import Listing4_Img16 from '../../assets/images/listing/listing3/16.webp';

import Listing5_Img1 from '../../assets/images/listing/listing4/1.webp';
import Listing5_Img2 from '../../assets/images/listing/listing4/2.webp';
import Listing5_Img3 from '../../assets/images/listing/listing4/3.webp';
import Listing5_Img4 from '../../assets/images/listing/listing4/4.webp';
import Listing5_Img5 from '../../assets/images/listing/listing4/5.webp';
import Listing5_Img6 from '../../assets/images/listing/listing4/6.webp';
import Listing5_Img7 from '../../assets/images/listing/listing4/7.webp';
import Listing5_Img8 from '../../assets/images/listing/listing4/8.webp';
import Listing5_Img9 from '../../assets/images/listing/listing4/9.webp';
import Listing5_Img10 from '../../assets/images/listing/listing4/10.webp';
import Listing5_Img11 from '../../assets/images/listing/listing4/11.webp';
import Listing5_Img12 from '../../assets/images/listing/listing4/12.webp';
import Listing5_Img13 from '../../assets/images/listing/listing4/13.webp';

import SingleListing2 from './SingleListing2';
import Slider from 'react-slick';

const HomeSliderListing = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };
  return (
    <>
        <Slider className="" {...settings} >
      <SingleListing2
      Img1={Listing1_Img1}
      Img2={Listing1_Img2}
      Img3={Listing1_Img3}
      Img4={Listing1_Img4}
      Img5={Listing1_Img5}
      Img6={Listing1_Img6}
      Img7={Listing1_Img7}
      Img8={Listing1_Img8}
      Img9={Listing1_Img9}
      Img10={Listing1_Img10}
      Img11={Listing1_Img11}
      Img12={Listing1_Img12}
      Img13={Listing1_Img13}
      Img14={Listing1_Img14}
      Img15={Listing1_Img15}
      Img16={Listing1_Img16}
      Img17={Listing1_Img17}
      Img18={Listing1_Img18}
      Img19={Listing1_Img19}
      Link="/apartment-for-sale-sharjah-aljada-naseej-district-il-teatro-residences-2"
      Title="Apartment in Il Teatro Residences 2, Naseej District"
      Address="Sharjah"
      Category="Apartments"
      Beds="1"
      Baths="2"
      SqFt="749 sqft / 70 sqm"
      Price="1,070,000"
      />       
      
      <SingleListing2
      Img1={Listing2_Img1}
      Img2={Listing2_Img2}
      Img3={Listing2_Img3}
      Img4={Listing2_Img4}
      Img5={Listing2_Img5}
      Img6={Listing2_Img6}
      Img7={Listing2_Img7}
      Img8={Listing2_Img8}
      Img9={Listing2_Img9}
      Img10={Listing2_Img10}
      Link="/apartment-for-sale-dubai-dubai-residence-complex-ag-7even"
      Title="Apartment in Ag 7even,Dubai Residence Complex"
      Address="Dubai"
      Category="Apartments"
      Beds="Studio"
      Baths="1"
      SqFt="371 sqft / 34 sqm"
      Price="449,000"
      />    
      
      <SingleListing2
      Img1={Listing3_Img1}
      Img2={Listing3_Img2}
      Img3={Listing3_Img3}
      Img4={Listing3_Img4}
      Img5={Listing3_Img5}
      Img6={Listing3_Img6}
      Img7={Listing3_Img7}
      Img8={Listing3_Img8}
      Img9={Listing3_Img9}
      Img10={Listing3_Img10}
      Img11={Listing3_Img11}
      Img12={Listing3_Img12}
      Img13={Listing3_Img13}
      Link="/townhouse-for-sale-sharjah-tilal-city-masaar-saro"
      Title={
        <>
        Townhouse in Saro, Masaar
        </>
      }      
      Address="Sharjah"
      Category="Townhouse"
      Beds="4"
      Baths="4"
      SqFt="1,916 sqft / 178 sqm"
      Price="2,802,000"
      />       
      
      <SingleListing2
      Img1={Listing4_Img1}
      Img2={Listing4_Img2}
      Img3={Listing4_Img3}
      Img4={Listing4_Img4}
      Img5={Listing4_Img5}
      Img6={Listing4_Img6}
      Img7={Listing4_Img7}
      Img8={Listing4_Img8}
      Img9={Listing4_Img9}
      Img10={Listing4_Img10}
      Img11={Listing4_Img11}
      Img12={Listing4_Img12}
      Img13={Listing4_Img13}
      Img14={Listing4_Img14}
      Img15={Listing4_Img15}
      Img16={Listing4_Img16}
      Link="/townhouse-for-sale-dubai-damac-hills-2-pacifica-casablanca-boutique-villas"
      Title="Townhouse in Casablanca Boutique Villas, Pacifica"
      Address="Dubai"
      Category="Townhouse"
      Beds="3"
      Baths="4"
      SqFt="1,881 sqft / 175 sqm"
      Price="1,599,995"
      />       
      
      <SingleListing2
      Img1={Listing5_Img1}
      Img2={Listing5_Img2}
      Img3={Listing5_Img3}
      Img4={Listing5_Img4}
      Img5={Listing5_Img5}
      Img6={Listing5_Img6}
      Img7={Listing5_Img7}
      Img8={Listing5_Img8}
      Img9={Listing5_Img9}
      Img10={Listing5_Img10}
      Img11={Listing5_Img11}
      Img12={Listing5_Img12}
      Img13={Listing5_Img13}
      Link="/villa-for-sale-dubai-dubai-south-dubai-world-central-emaar-south-greenviews-2"
      Title={
        <>
        Villa in Greenviews 2, Emaar South
        </>
      }      
      Address="Dubai"
      Category="Villa"
      Beds="3"
      Baths="4"
      SqFt="1,553 sqft / 144 sqm"
      Price="2,199,995"
      />       
      </Slider>   
    </>
  );
}

export default HomeSliderListing;
