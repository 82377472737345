import React from 'react';
import {Link} from 'react-router-dom';
import WhatsApp from '../../assets/images/WhatsApp.png';
import BottomFooter from '../layout/BottomFooter';
import FooterLogo from '../../assets/images/800home/Logo.webp';
import { Envelope, EnvelopeFill, Facebook, GeoAlt, Instagram, Linkedin, Phone, Telephone, TelephoneFill, Tiktok, Whatsapp} from 'react-bootstrap-icons';

// Sources


const Footer = () => {
  return (
    <>
        <section id='website-responsive' className="container-fluid bg-footer py-5 mt-2 mt-md-0">
          <div className="row justify-content-center align-items-start py-md-4 gy-4">
            <div className="col-11 col-lg-3 text-start pb-4 pb-md-0">
              <div className="col-12 mx-auto">
              <div className="pb-2 pb-md-4">
              <img className='footer-logo' src={FooterLogo} alt="Footer Logo" />
              </div>
              <div className='text-white fs-ss-16 pb-2 pb-md-4 fw-300 col-11 col-md-9 text-start'>
              Discover prime investment opportunities in Dubai with 800 Homes – Where your real estate journey begins.
              </div>
              <div id='social-media' className='pt-2 pt-md-0'>
              <div className="text-ss-secondary fs-ss-22 text-white">
              <a href="https://www.facebook.com/800Homes" target="_blank" className='text-decoration-none text-white text-hover-ss-primary fw-300' > <Facebook className='me-2'/> </a>
              <a href="https://www.instagram.com/800homesae/" target="_blank" className='text-decoration-none text-white text-hover-ss-primary fw-300' > <Instagram className='me-2'/> </a>
              <a href="https://www.tiktok.com/@800homes" target="_blank" className='text-decoration-none text-white text-hover-ss-primary fw-300' > <Tiktok className='me-2'/> </a>
              <a href="https://www.linkedin.com/company/800-homes-real-estate-brokerage-firm/" target="_blank" className='text-decoration-none text-white text-hover-ss-primary fw-300' > <Linkedin className='me-2'/> </a>
              </div>
              </div>
              </div>
            </div>

            <div className="col-5 col-lg-2 mt-md-5">
                <div className="text-ss-secondary fs-ss-22 fw-500 text-white text-uppercase">
                Properties
                </div>
                <div className="text-white fs-ss-16 pt-2 pt-md-3">
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/properties-for-sale"> Appartments </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/properties-for-sale"> Penthouses </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/properties-for-sale"> Townhouses </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/properties-for-sale"> Commercial </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/properties-for-sale"> Villas </Link> </div>
                </div>
            </div>

            <div className="col-6 col-lg-2 mt-md-5">
                <div className="text-ss-secondary fs-ss-22 fw-500 text-white text-uppercase">
                  Quick Links
                </div>
                <div className="text-white fs-ss-16 pt-2 pt-md-3">
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/faqs"> Faqs </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/why-dubai"> Why Dubai </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/leadership"> Leadership </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/privacy-policy"> Privacy Policy </Link> </div>
                <div className="mb-1 mb-md-2"> <Link className='text-decoration-none text-white text-hover-ss-primary fw-300' to="/terms-and-conditions"> Terms & Conditions </Link> </div>
                </div>
            </div>

            <div className="col-11 col-lg-3 mt-md-5">
                <div className="text-ss-secondary fs-ss-22 fw-500 text-white text-uppercase">
                Contact Us
                </div>
                <div className="pt-2 pt-md-3">
              <div className="pb-2"> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href="tel:80046637" target='_blank'> <Telephone className='text-ss-primary me-2'/> 80046637 </a> 
               </div>
               <div className="pb-2"> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href="tel:+971585746637" target='_blank'> <Phone className='text-ss-primary me-2'/> +971-58-574-6637 </a> 
               </div>
               <div className="pb-2"> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" target='_blank'> <Whatsapp className='text-ss-primary me-2'/> +971-58-574-6637 </a> 
               </div>
               <div className="pb-2"> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href="mailto:inquiry@800homes.ae" target='_blank'> <Envelope className='text-ss-primary me-2'/> inquiry@800homes.ae </a> 
               </div>

               <div className="pb-2 text-white text-hover-ss-primary fw-300"> 
              <GeoAlt className='text-ss-primary me-2'/> 
               1104-72, Xavier Business Center, <br className=""/> 
               <span className='ms-4'/> Ibn Battuta Gate Offices, <br className=""/> 
               <span className='ms-4'/> Dubai, UAE
               </div>
                </div>
            </div>

          </div>
        </section>
        <section className="container-fluid bg-white fs-ss-14 text-black text-center lh-sm py-2">
        Copyright © 2024, 800 Homes - Real Estate Brokerage.
        </section>

        <div className="">
          <div className="d-none d-md-block" >
        <a className="" href="https://web.whatsapp.com/send?phone=971585746637&text=" target="_blank">
        <img src={WhatsApp} className='w-icons position-fixed' alt="" style={{bottom:"20px", right:"20px", fontSize:"48px"}}/>
        </a>
        </div>

        <div className="d-block d-md-none" >
        <a className="" href="https://api.whatsapp.com/send?phone=971585746637&text=" target="_blank">
        <img src={WhatsApp} className='w-icons position-fixed' alt="" style={{bottom:"20px", right:"20px", fontSize:"48px"}}/>
        </a>
        </div>
        </div>
    </>
  );
}

export default Footer;
