import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SingleListing from './SingleListing';
import Penthouses from './Penthouses';
import Apartments from './Apartments';
import Townhouses from './Townhouses';
import Villas from './Villas';

const MainPageOfListing = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  }
const handleClick = (btn_id) => {
    setID(btn_id)
    console.log('btn_id',btn_id)
}

    const [id,setID] = useState(1)

  return (
    <>
    <div id='website-responsive' className='py-5'>
    <Tabs>

    <TabList className="text-center fs-ss-18 fw-300 mx-auto" data-aos="fade-up">
      <Tab> 
          <div className=''>
            All Properties
          </div>
      </Tab>

      <Tab> 
          <div className=''>
            Apartments
          </div>
      </Tab>

      <Tab> 
          <div className=''>
            Penthouses
          </div>
      </Tab>

      <Tab> 
          <div className=''>
            Townhouses
          </div>
      </Tab>

      <Tab> 
          <div className=''>
            Villas
          </div>
      </Tab>
    </TabList>

    <section className="container-fluid px-md-0 pt-md-2 ">
      <div className="ms-md-4 ps-md-3">
    <TabPanel>
      <div className="row">
        <Apartments/>
        <Townhouses/>
        <Villas/>
      </div>
    </TabPanel>

    <TabPanel>
      <div className="row">
        <Apartments/>
      </div>
    </TabPanel>

    <TabPanel>
      <div className="row">
        <Penthouses/>
      </div>
    </TabPanel>
    
    <TabPanel>
      <div className="row">
        <Townhouses/>
      </div>
    </TabPanel>

    <TabPanel>
      <div className="row">
        <Villas/>
      </div>
    </TabPanel>
    </div>
    </section>

  </Tabs>  
    </div>
    </>
  )
}

export default MainPageOfListing